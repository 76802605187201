import React from "react";
import pimg1 from '../../images/icon/elementor.svg'
import pimg2 from '../../images/icon/elementor-pro.svg'
import pimg3 from '../../images/icon/woocommerce.png'
import pimg4 from '../../images/icon/1.png'
import pimg5 from '../../images/icon/yoast-logo.svg'
import pimg6 from '../../images/icon/loco.png'
import pimg7 from '../../images/icon/really-simple.png'
import pimg8 from '../../images/icon/wordfence-security-logo.svg'
import pimg9 from '../../images/icon/udp-logo.svg'
import pimg10 from '../../images/icon/km.png'
import pimg11 from '../../images/icon/cf7-logo.svg'
import pimg12 from '../../images/icon/mailchimp.png'

const Plugins = () => {
    return (
        <section className="wpo-awesome-features-section section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col col-lg-5">
                        <div className="wpo-section-title">
                            <h2>Compatible plugins</h2>
                            <p>This is a vast collection of plugins compatible with Charitio. we make sure that you can build rich Nonprofit Charity sites.</p>
                        </div>
                    </div>
                </div>
                <div className="wpo-awesome-features-wrapper">
                    <div className="row">
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="wpo-awesome-features-item">
                                <div className="wpo-awesome-features-icon">
                                    <i><img src={pimg1} alt="" /></i>
                                </div>
                                <div className="wpo-awesome-features-text">
                                    <h2>Elementor Page Builder</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="wpo-awesome-features-item">
                                <div className="wpo-awesome-features-icon">
                                    <i><img src={pimg2} alt="" /></i>
                                </div>
                                <div className="wpo-awesome-features-text">
                                    <h2>Elementor PRO</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="wpo-awesome-features-item">
                                <div className="wpo-awesome-features-icon">
                                    <i><img src={pimg3} alt="" /></i>
                                </div>
                                <div className="wpo-awesome-features-text">
                                    <h2>WooCommerce</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="wpo-awesome-features-item">
                                <div className="wpo-awesome-features-icon">
                                    <i><img src={pimg4} alt="" /></i>
                                </div>
                                <div className="wpo-awesome-features-text">
                                    <h2>Slider Revolution</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="wpo-awesome-features-item">
                                <div className="wpo-awesome-features-icon">
                                    <i><img src={pimg5} alt="" /></i>
                                </div>
                                <div className="wpo-awesome-features-text">
                                    <h2>Yoast SEO</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="wpo-awesome-features-item">
                                <div className="wpo-awesome-features-icon">
                                    <i><img src={pimg6} alt="" /></i>
                                </div>
                                <div className="wpo-awesome-features-text">
                                    <h2>Loco Translate</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="wpo-awesome-features-item">
                                <div className="wpo-awesome-features-icon">
                                    <i><img src={pimg7} alt="" /></i>
                                </div>
                                <div className="wpo-awesome-features-text">
                                    <h2>Really Simple SSL</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="wpo-awesome-features-item">
                                <div className="wpo-awesome-features-icon">
                                    <i><img src={pimg8} alt="" /></i>
                                </div>
                                <div className="wpo-awesome-features-text">
                                    <h2>Wordfence Security
                                        Firewall & Malware</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="wpo-awesome-features-item">
                                <div className="wpo-awesome-features-icon">
                                    <i><img src={pimg9} alt="" /></i>
                                </div>
                                <div className="wpo-awesome-features-text">
                                    <h2>Lifetime Update</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="wpo-awesome-features-item">
                                <div className="wpo-awesome-features-icon">
                                    <i><img src={pimg10} alt="" /></i>
                                </div>
                                <div className="wpo-awesome-features-text">
                                    <h2>WP Super Cache</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="wpo-awesome-features-item">
                                <div className="wpo-awesome-features-icon">
                                    <i><img src={pimg11} alt="" /></i>
                                </div>
                                <div className="wpo-awesome-features-text">
                                    <h2>Contact Form 7</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="wpo-awesome-features-item">
                                <div className="wpo-awesome-features-icon">
                                    <i><img src={pimg12} alt="" /></i>
                                </div>
                                <div className="wpo-awesome-features-text">
                                    <h2>MC4WP</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Plugins;