import React from 'react';
import {Link} from 'react-router-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import MobileMenu from '../../components/MobileMenu'
import logo from '../../images/logo.svg'


const Header = () => {

    return(

        <header id="header">
        <div className="wpo-site-header wpo-header-style-1">
            <nav className="navigation navbar navbar-expand-lg navbar-light">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                            <div className="mobail-menu">
                                <MobileMenu/>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-6">
                            <div className="navbar-header">
                                <Link className="navbar-brand" to="/"><img src={logo}
                                        alt=""/></Link>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-1 col-1">
                            <div id="navbar" className="collapse navbar-collapse navigation-holder">
                                <button className="menu-close"><i className="ti-close"></i></button>
                                <ul className="nav navbar-nav mb-2 mb-lg-0">
                                    <li><AnchorLink href="#demo">DEMOS</AnchorLink></li>
                                    <li><AnchorLink href="#pages">PAGES</AnchorLink></li>
                                    <li><a target="_blank" href="https://support.wpocean.com/">SUPPORT</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-2 col-2">
                            <div className="header-right">
                                <div className="close-form">
                                    <a target="_blank" className="theme-btn" href="https://1.envato.market/charitio-wp">Purchase</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </header>
        
    )
}

export default Header;
